import React, { useState } from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPreviewGrid from "../components/project-preview-grid";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import { useItemContext } from "../context/item/context";
import { responsiveTitle1 } from "../components/typography.module.css";
import Form from "../components/form";
export const query = graphql`
  query FavoritesPageQuery {
    projects: allSanitySampleProject(filter: { slug: { current: { ne: null } } }) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const FavoritesPage = (props) => {
  const { data, errors } = props;
  const [favorites, setFavorites] = useState([]);
  const { state } = useItemContext();

  function getIds() {
    const ids = [];
    if (state && state.item) {
      state.item.forEach((item) => {
        ids.push(item.id);
      });
    }
    return ids;
  }

  React.useEffect(() => {
    const f = mapEdgesToNodes(data.projects).filter((item) => getIds().includes(item.id));
    setFavorites(f);
  }, []);
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  return (
    <Layout>
      <SEO title="Kedvencek" />
      <Container>
        <h1 className={responsiveTitle1}>Kedvencek</h1>
        {data.projects && data.projects.edges && data.projects.edges.length > 0 && (
          <ProjectPreviewGrid nodes={favorites} />
        )}
        <Form withProducts />
      </Container>
    </Layout>
  );
};

export default FavoritesPage;
